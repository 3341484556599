// -----------------------------------------
// input-group and input-group-addon styles
//  note: form-groups are not required
//
@mixin input-group-button-variation($vertical-padding) {
  .input-group-btn {
    .btn {
      //margin: 0 0 $vertical-padding 0;
    }
  }
}

// default margin - no form-group required
@include input-group-button-variation(input-padding-y);

.bmd-form-group-sm {
  @include input-group-button-variation($input-padding-y-sm);
}

.bmd-form-group-lg {
  @include input-group-button-variation($input-padding-y-lg);
}

.input-group {
  // may be in or outside of form-group

  .input-group-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px 0 15px;
    background-color: transparent;
    border-color: transparent;
  }

}

.search-bar-table{
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;

  }


}

@media (min-width: 900px) {
  .search-bar-list{
    width: 280px;
  }
}

.input-custom{

  .mat-mdc-form-field-infix{
    min-height: 45px !important;
    max-height: 45px !important;
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label{
    top: 50% !important;
  }
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above{
    transform: translateY(-28px) scale(0.75) !important;
  }
}

.mdc-list-item__primary-text{
  font-weight: bold;

}


.custom-label{
  display: block !important;
  min-height: 0 !important;
  span{
    min-height: 0;
  }

}

.mat-mdc-form-field-icon-suffix{
  display: flex;
}
.mat-datepicker-toggle .mat-mdc-button-base{
  width: 48px !important;
  height: 48px !important;
  padding: 12px !important;
}

.focus-none {

  &:focus {
    outline: none;
    border: none;
    outline: 0;
    box-shadow: none;

  }
}


.dark-input{
input::placeholder { /* Most modern browsers support this now. */
  color:    $gray-dark;
}
}


