body{
    background-color: #eee;
    color: $black-color;
    font-weight: 300;
}

legend {
  border-bottom: 0;
}

.serif-font{
    font-family: $font-family-serif;
}

// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: 0;
  }
}

a{
  color: $link-color;
  &:hover,
  &:focus{
      color: darken($link-color, 5%);
      text-decoration: none;
  }

  &.text-info{
      &:hover, &:focus{
          color: darken($brand-info, 5%);
      }
  }

  & .material-icons {
    vertical-align: middle;
  }
}

.form-check,
label{
    font-size: 14px;
    line-height: 1.42857;
    color: $checkboxes-text-color;
    font-weight: 400;
}

/*           Animations              */
.animation-transition-general{
    @include transition-all($general-transition-time, $transition-linear);
}

.animation-transition-slow{
    @include transition-all($slow-transition-time, $transition-linear);
}

.animation-transition-fast{
    @include transition-all($fast-transition-time, $transition-ease);
}

.caret,
.sidebar a{
    @include transition-all($fast-transition-time, $transition-ease-in);
}
#mapContainer {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    //margin-top: 70px;
}
