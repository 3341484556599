// Bootstrap brand color customization


/*     brand Colors              */

$primary-50: #fff3e0 !default;
$primary-100: #ffe0b2 !default;
$primary-200: #ffcc80 !default;
$primary-300: #ffb74d !default;
$primary-400: #ffa726 !default;
$primary-500: #ff9800 !default;
$primary-600: #fb8c00 !default;
$primary-700: #f57c00 !default;
$primary-800: #ef6c00 !default;
$primary-900: #e65100 !default;
$primary-a100: #ffd180 !default;
$primary-a200: #ffab40 !default;
$primary-a400: #ff9100 !default;
$primary-a700: #ff6d00 !default;
$primary: $primary-500!default;


$brand-primary:              $primary !default;
$brand-info:                 $cyan-500 !default;
$brand-success:              $green-500 !default;
$brand-warning:              $amber-500 !default;
$brand-danger:               $red-500 !default;
$brand-rose:                 $pink-500 !default;
$brand-inverse:              $black-color !default;




