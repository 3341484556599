/*!

=========================================================
* Material Dashboard Angular - v2.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-angular2
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-angular2/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
@use '@angular/material' as mat;
@use 'button-material' as button;

@include mat.core();

 @import "~@angular/material/prebuilt-themes/indigo-pink.css";

 @import "core/variables";
 @import "core/mixins";
 @import "bootstrap/scss/bootstrap";


 // Core Components
 @import "core/buttons";
 @import "core/checkboxes";
 @import "core/radios";
 @import "core/forms";
 @import "core/input-group";
 @import "core/images";
 @import "core/navbar";
 @import "core/alerts";
 @import "core/type";
 @import "core/tabs";
 @import "core/tooltip";
 @import "core/popover";
 @import "core/dropdown";
 @import "core/togglebutton";
 @import "core/ripples";
 @import "core/footers";
 @import "core/sidebar-and-main-panel";
 @import "core/fixed-plugin";
 @import "core/tables";
 @import "core/misc";
 @import "core/chips";
 @import "core/grid";

@import "core/cards";
@import "core/cards/card-stats";
@import "core/cards/card-profile";
@import "core/cards/card-plain";
@import "core/clockFace";

 //plugin scss
 @import "core/plugins/animate";
 @import "core/plugins/chartist";
 @import "core/plugins/perfect-scrollbar";

 @import "core/responsive";

@import "core/angular-modal.scss";
@import "mapbox-gl/dist/mapbox-gl.css";
@import "gridstack/dist/gridstack.min.css";

$md-primary: (
  50: $orange-50,
  100: $orange-100,
  200: $orange-200,
  300: $orange-300,
  400: $orange-400,
  500: $orange-500,
  600: $orange-600,
  700: $orange-700,
  800: $orange-800,
  900: $orange-900,
  A100: $orange-a100,
  A200: $orange-a200,
  A400: $orange-a400,
  A700:$orange-a700,
  contrast: (
    50: $grey-50,
    100: $grey-100,
    200: $grey-200,
    300: $grey-300,
    400: $grey-400,
    500: $grey-500,
    600: $grey-600,
    700: $grey-700,
    800: $grey-800,
    900: $grey-900,
    A100: $grey-a100,
    A200: $grey-a200,
    A400: $grey-a400,
    A700: $grey-a700,
  ),
);

$md-accent: (
  50: $grey-50,
  100: $blue-100,
  200: $grey-200,
  300: $grey-300,
  400: $grey-400,
  500: $grey-500,
  600: $grey-600,
  700: $grey-700,
  800: $grey-800,
  900: $grey-900,
  A100: $grey-a100,
  A200: $grey-a200,
  A400: $grey-a400,
  A700: $grey-a700,
  contrast: (
    50: $grey-50,
    100: $grey-100,
    200: $grey-200,
    300: $grey-300,
    400: $grey-400,
    500: $grey-500,
    600: $grey-600,
    700: $grey-700,
    800: $grey-800,
    900: $grey-900,
    A100: $grey-a100,
    A200: $grey-a200,
    A400: $grey-a400,
    A700: $grey-a700,
  ),
);

$md-alternative: (
  50: $cyan-50,
  100: $cyan-100,
  200: $cyan-200,
  300: $cyan-300,
  400: $cyan-400,
  500: $cyan-500,
  600: $cyan-600,
  700: $cyan-700,
  800: $cyan-800,
  900: $cyan-900,
  A100: $cyan-a100,
  A200: $cyan-a200,
  A400: $cyan-a400,
  A700: $cyan-a700,
  contrast: (
    50: $grey-50,
    100: $grey-100,
    200: $grey-200,
    300: $grey-300,
    400: $grey-400,
    500: $grey-500,
    600: $grey-600,
    700: $grey-700,
    800: $grey-800,
    900: $grey-900,
    A100: $grey-a100,
    A200: $grey-a200,
    A400: $grey-a400,
    A700: $grey-a700,
  ),
);

$md-warn: (
  50: $amber-50,
  100: $amber-100,
  200: $amber-200,
  300: $amber-300,
  400: $amber-400,
  500: $amber-500,
  600: $amber-600,
  700: $amber-700,
  800: $amber-800,
  900: $amber-900,
  A100: $amber-a100,
  A200: $amber-a200,
  A400: $amber-a400,
  A700: $amber-a700,
  contrast: (
    50: $grey-50,
    100: $grey-100,
    200: $grey-200,
    300: $grey-300,
    400: $grey-400,
    500: $grey-500,
    600: $grey-600,
    700: $grey-700,
    800: $grey-800,
    900: $grey-900,
    A100: $grey-a100,
    A200: $grey-a200,
    A400: $grey-a400,
    A700: $grey-a700,
  ),
);

$md-error: (
  50: $red-50,
  100: $red-100,
  200: $red-200,
  300: $red-300,
  400: $red-400,
  500: $red-500,
  600: $red-600,
  700: $red-700,
  800: $red-800,
  900: $red-900,
  A100: $red-a100,
  A200: $red-a200,
  A400: $red-a400,
  A700: $red-a700,
  contrast: (
    50: $grey-50,
    100: $grey-100,
    200: $grey-200,
    300: $grey-300,
    400: $grey-400,
    500: $grey-500,
    600: $grey-600,
    700: $grey-700,
    800: $grey-800,
    900: $grey-900,
    A100: $grey-a100,
    A200: $grey-a200,
    A400: $grey-a400,
    A700: $grey-a700,
  ),
);

$my-primary: mat.define-palette($md-primary, 500);
$my-accent: mat.define-palette($md-accent, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette($md-error);

$my-theme: mat.define-light-theme(
    (
      color: (
        primary: $my-primary,
        accent: $my-accent,
        warn: $my-warn,
      ),
    )
);

@include mat.all-component-themes($my-theme);
// @include mat.core-theme($my-theme);
// @include mat.toolbar-theme($my-theme);
@include button.theme($my-theme);


