.grid-container {
  display: grid;
  width: 100%;
  height: 100vh;
  grid-gap: 8px;

  @media (max-width: 768px) {
    grid-gap: 0;
  }

  /* @see https://stackoverflow.com/a/43312314 */
  min-width: 0;
  min-height: 0;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > ul {
      flex: 1 1 auto;
    }

    h4 {
      flex: 0 1 auto;
    }
  }
}

.grid-item {
  //@extend .md-whiteframe-z1;

  position: relative;

  /* @see https://stackoverflow.com/a/43312314 */
  min-width: 0;
  min-height: 0;

  padding: 8px;

  @media (max-width: 768px) {
    padding: 4px;
  }

  > div {
    flex: 1 1 auto;
  }

  .user-states {
    max-height: none;
  }

  .leaflet-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.grid-item-edit {
  align-items: center;
  z-index: 20;

  svg.svg-inline--fa {
    background-color: #fff;
    border-radius: 100%;
    font-size: 2.5em;
  }

  //&.cell-bg {
  //  @extend .md-whiteframe-z1;
  //}

  &.cell-fg {
    background: rgba(255, 255, 255, 0.8);
  }
}

.grid-item-size-controls {
  z-index: 20;

  svg.svg-inline--fa {
    margin: 4px;
  }

  &.column-span {
    justify-self: end;
    align-self: center;
    border: 1px solid #ccc;
    border-right: 0;
    border-radius: 40% 0 0 40%;
    box-shadow: -1px 0 1px rgba(0, 0, 0, 0.4), -2px 0 6px rgba(0, 0, 0, 0.2);
    padding: 4px 0 4px 8px;
    background-color: #fff;
  }

  &.row-span {
    justify-self: center;
    align-self: end;
    border: 1px solid #ccc;
    border-bottom: 0;
    border-radius: 40% 40% 0 0;
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.4), 0 -2px 6px rgba(0, 0, 0, 0.2);
    padding: 4px 4px 0 4px;
    background-color: #fff;
  }
}

.grid-control {
  grid-column-start: 1;
  grid-row-start: 1;

  .svg-inline--fa.clickable {
    padding: 0;
  }

  &.column-numbers {
    justify-self: end;
    align-self: center;
    border: 1px solid #ccc;
    border-left: 0;
    border-radius: 0 40% 40% 0;
    box-shadow: 1px 0 1px rgba(0, 0, 0, 0.4), 6px 0 6px rgba(0, 0, 0, 0.2);
    padding: 4px 8px 4px 2px;
    background-color: #fff;
    position: relative;
    right: -30px;
    z-index: 100;
  }

  &.row-numbers {
    justify-self: center;
    align-self: end;
    border: 1px solid #ccc;
    border-top: 0;
    border-radius: 0 0 40% 40%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4), 0 6px 6px rgba(0, 0, 0, 0.2);
    padding: 2px 4px 4px 4px;
    background-color: #fff;
    position: relative;
    bottom: -37px;
    z-index: 100;
  }
}

.track-size-control {
  input {
    position: relative;
    border: 0;
    border-bottom: 1px dashed #ccc;
    text-align: center;
    width: 60px;

    &:focus {
      border: 1px solid transparentize($brand-primary, 0.2);
    }
  }

  &.row-size {
    justify-self: start;
    align-self: center;

    input {
      left: -70px;
    }
  }

  &.column-size {
    justify-self: center;
    align-self: start;

    input {
      top: -40px;
    }
  }
}

.grid-container-edit-wrapper {
  margin-top: -15px;

  .grid-container {
    border: 2px solid #ccc;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    margin: 40px 40px 40px 80px;
    width: calc(100vw - 120px);
    height: calc(100vh - 135px);
  }

  .leaflet-container {
    .leaflet-pane,
    .leaflet-control-container > *,
    .leaflet-control {
      z-index: auto !important;
    }
  }
}

.grid-home-btn {
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: 1050;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.475);
  border-radius: 100%;

  img {
    width: 32px;
    transition: width 200ms ease-in-out, height 200ms ease-in-out;
  }

  &:hover {
    img {
      width: 48px;
    }
  }
}

.scrollable {
  max-height: 100%;
  overflow: auto;
}

.grid-container-edit-wrapper2 {
  // margin-top: -15px;
  flex: 1 1 auto;

  .grid-stack {
    border-top: 2px solid #ccc;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    margin: 15px 0;

    &.ui-droppable-active {
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
    }
  }

  .leaflet-container {
    .leaflet-pane,
    .leaflet-control-container > *,
    .leaflet-control {
      z-index: auto !important;
    }
  }
}

.grid-stack-item {
  &.grid-placeholder {
    border: 0;
    box-shadow: none;

    .ui-resizable-handle {
      display: none !important;
    }
  }

  &.ui-draggable-dragging {
    .grid-stack-item-content-child {
      .edit-cover {
        cursor: grabbing;
      }
    }
  }

  .grid-stack-item-content {
    position: relative;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  &.frameless {
    .grid-stack-item-content-child {
      border: 0;
      box-shadow: none;
    }
  }
}

.grid-stack-item-content-child {
  //@extend .md-whiteframe-z1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px;

  padding: 8px;

  @media (max-width: 768px) {
    padding: 4px;
  }

  > div,
  > ul {
    flex: 1 1 auto;
  }

  > div.item-header {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;

    h4 {
      flex: 1 1 auto;
    }

    .svg-inline--fa {
      flex: 0 1 auto;
    }
  }

  // h4 {
  //   flex: 0 1 auto;
  // }

  .user-states {
    max-height: none;
  }

  .leaflet-container,
  .mapboxgl-map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .edit-cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(78, 75, 75, 0.8);
    z-index: 70;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;

    svg.svg-inline--fa {
      background-color: #fff;
      border-radius: 100%;
      font-size: 2.5em;
      width: 1.5em;
      height: 1.5em;
    }
  }
}

.grid-item-gallery {
  //@extend .layout-row;

  max-width: 100vw;

  overflow-x: auto;
  padding: 4px;
}

.new-grid-item {
  flex: 0 0 auto;
  margin: 12px;
  padding: 8px;
  width: 180px;
  height: 80px;
  cursor: grab;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid-stack {
  > .grid-stack-item {
    > .ui-resizable-se {
      bottom: 5px;
      background-color: #fff;
      border-radius: 100%;
    }

    > .ui-resizable-sw {
      background: none;
    }
  }
}

.grid-stack-fullscreen-indicator {
  position: absolute;
  margin: 0;
  top: 100vh;
  width: 100%;
  border: 3px dashed #ccc;
}


.NgxEditor{

  min-height: 250px !important;

}

