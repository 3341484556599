.card-profile{
  margin-top: 30px;
  text-align: center;


  .card-avatar {
      margin: -50px auto 0;
      border-radius: 50%;
      overflow: hidden;
      padding: 0;

      @include shadow-big();

      & + .card-body {
          margin-top: 15px;
      }
      img {
          width: 100%;
          height: auto;
      }
  }

  .card-body + .card-footer {
      margin-top: -15px;
  }

  .card-footer {
      .btn.btn-just-icon {
          font-size: 20px;
          padding: 12px 12px;
          line-height: 1em;
      }
  }

  &.card-plain {
      .card-avatar {
          margin-top: 0;
      }
  }

  .card-header:not([class*="card-header-"]){
    background: transparent;
  }
  .card-avatar {
    max-width: 130px;
    max-height: 130px;
  }
}

.small-button{
  width: 30px !important;
  height: 30px !important;
  .mat-icon{
  font-size: 18px !important;
    height: 18px !important;
    width: 18px !important;
  }
}

.card-events {

  background-color: $light-green-600;
  .card-body{
    .card-title, .card-description{
      color: $white !important;
    }
    .card-title{
      margin-bottom: 0 !important;
      font-size: 22px !important;
    }
    .card-category{
      color: rgba($white, .8);
      margin: 0 0 30px !important;
      font-size: 14px;
    }
  }


.number-value{
  display: inline-block;
  margin: 20px 0 22px;
  font-size: 4em;
  font-weight: 700;
}

}
.card-event-finished{
  background-color: $grey-600;
}
