.clock {
  position: relative;
  display: flex;

  .digital-clock,
  .clock-face {
    overflow: visible;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

/* digital clock styles */

.digital-clock {
  padding-left: 25px;
  padding-right: 25px;

  &.standard {
    padding-left: 20px;
    padding-right: 20px;

    text {
      font-family: arial, cursive;
      fill: #272e38;
    }

    text.divider {
      margin-left: 20px;
    }
  }

  &.standard-dark {
    background-color: #545271;
    border-radius: 2px;

    text {
      font-family: arial;
      fill: #fefefc;
    }

    text.divider {
      fill: #f55;
    }
  }

  &.modern-green {
    background-color: #121212;
    border-radius: 25px;

    text {
      font-family: "Courier New", Courier, monospace;
      fill: #66ff99;
    }

    text.divider {
      transform: translateX(-1px);
    }
  }

  &.modern-colorless {
    background-color: #000000;
    border-radius: 50px;

    text {
      font-family: Georgia, "Times New Roman", Times, serif;
      fill: #f0f0f0;
    }
  }

  &.modern-red {
    background-color: rgb(226, 113, 7);
    border-radius: 50px;

    text {
      font-family: arial, cursive;
      fill: rgb(255, 255, 255);
    }
  }

  &.antique {
    background-color: rgb(245, 242, 218);
    border-radius: 25px;

    text {
      font-family: fantasy;
      stroke-width: 1px;
      fill: rgb(0, 0, 0);
    }

    text.divider {
      fill: rgb(231, 0, 0);
    }
  }
}

/* analogue clock styles */

%invisible {
  .hour-marker,
  .minute-marker {
    display: none;
  }
}

%black-round {
  stroke: rgb(12, 12, 12);
  stroke-linecap: round;
}

.clock-face {
  &.standard {
    > circle.face {
      stroke-width: 2px;
      fill: #fefefc;
      stroke: #545271;
    }

    text.clock-number {
      font-size: 9px;
      font-family: arial;
      fill: #84848d;
    }

    @extend %invisible;

    .hour-hand,
    .minute-hand {
      stroke-width: 2px;
      stroke: #61afff;
    }

    .second-hand {
      stroke-width: 1px;
      stroke: #f55;
    }
  }

  &.standard-dark {
    > circle.face {
      fill: #545271;
    }

    text.clock-number {
      font-size: 9px;
      font-family: arial;
      fill: #fefefc;
    }

    @extend %invisible;

    .hour-hand,
    .minute-hand {
      stroke-width: 2px;
      stroke: #61afff;
    }

    .second-hand {
      stroke-width: 1px;
      stroke: #f55;
    }
  }

  &.modern-green {
    > circle.face {
      stroke-width: 6px;
      stroke: #67d2c8;
      fill: rgb(255, 255, 255);
      transform: scale(0.96) translate(2px, 2px);
    }

    text.clock-number {
      display: none;
    }

    .hour-marker,
    .minute-marker,
    .hour-hand,
    .minute-hand {
      stroke: rgb(0, 0, 0);
    }

    .hour-marker,
    .hour-hand {
      stroke-width: 3px;
    }

    .minute-marker,
    .minute-hand {
      stroke-width: 1px;
    }

    .second-hand {
      stroke-width: 2px;
      stroke: rgb(231, 0, 0);
    }
  }

  &.modern-colorless {
    > circle.face {
      stroke-width: 5px;
      stroke: rgb(12, 12, 12);
      fill: rgb(248, 248, 248);
    }

    text.clock-number,
    .minute-marker {
      display: none;
    }

    .hour-marker,
    .hour-hand,
    .minute-hand,
    .second-hand {
      @extend %black-round;
    }

    .hour-marker,
    .hour-hand {
      stroke-width: 4px;
    }

    .minute-hand {
      stroke-width: 3px;
    }

    .second-hand {
      stroke-width: 1px;
    }
  }

  &.modern-red {
    > circle.face,
    text.clock-number,
    .minute-marker {
      display: none;
    }

    .hour-marker,
    .hour-hand,
    .minute-hand {
      stroke-width: 2px;
    }

    .hour-marker {
      stroke: #c70000;
    }

    .hour-hand,
    .minute-hand,
    .second-hand {
      box-shadow: 0 0 1px white;
    }

    .hour-hand,
    .minute-hand {
      stroke: rgb(0, 0, 0);
    }

    .second-hand {
      stroke-width: 1px;
      stroke: rgb(78, 78, 78);
    }
  }

  &.antique {
    > circle.face {
      stroke-width: 2px;
      stroke: rgb(49, 49, 49);
      fill: rgb(245, 242, 218);
      box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
      border-radius: 25px;
    }

    text.clock-number {
      font-size: 9px;
      font-family: fantasy;
      fill: rgb(49, 49, 49);
    }

    @extend %invisible;

    .hour-hand,
    .minute-hand {
      stroke: rgb(0, 0, 0);
    }

    .hour-hand {
      stroke-width: 3px;
    }

    .minute-hand {
      stroke-width: 1px;
    }

    .second-hand {
      stroke-width: 2px;
      stroke: rgb(231, 0, 0);
    }
  }
}
