.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {

  --mdc-chip-label-text-color: white !important;
  --mdc-chip-disabled-label-text-color: white !important;
  --mdc-chip-with-icon-icon-color: white !important;
  --mdc-chip-with-icon-disabled-icon-color: white !important;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white !important;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white !important;
  --mdc-chip-with-icon-selected-icon-color: white !important;
}
