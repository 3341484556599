

.mat-mdc-snack-bar-container {
  &.alert-danger{
    --mdc-snackbar-container-color: $brand-danger;
    --mdc-snackbar-supporting-text-color: $mdb-text-color-light;
    --mat-mdc-snack-bar-button-color:$brand-danger;


  }
  &.alert-warning{
    --mdc-snackbar-container-color: $brand-warning;
    --mdc-snackbar-supporting-text-color: $mdb-text-color-light;
    --mat-mdc-snack-bar-button-color: $brand-warning;
  }
  &.alert-success{
    --mdc-snackbar-container-color: $brand-success;
    --mdc-snackbar-supporting-text-color: $mdb-text-color-light;
    --mat-mdc-snack-bar-button-color: $brand-success;

  }
  &.alert-info{
    --mdc-snackbar-container-color: $brand-info;
    --mdc-snackbar-supporting-text-color: $mdb-text-color-light;
    --mat-mdc-snack-bar-button-color: $brand-info;
  }
  &.alert-primary{
    --mdc-snackbar-container-color: $brand-primary;
    --mdc-snackbar-supporting-text-color: $mdb-text-color-light;
    --mat-mdc-snack-bar-button-color: $brand-primary;
  }
  &.alert-rose{
    --mdc-snackbar-container-color: $brand-rose;
    --mdc-snackbar-supporting-text-color: $mdb-text-color-light;
    --mat-mdc-snack-bar-button-color: $brand-rose;
  }
}
//
//.alert {
//  background-color:brown;
//  color:white;
//    border: 0;
//    border-radius: 3px;
//    position: relative;
//    padding: 20px 15px;
//    line-height: 20px;
//
//    b{
//        font-weight: $font-weight-bold;
//        text-transform: uppercase;
//        font-size: $font-size-small;
//    }
//    // SASS conversion note: please mirror any content change in _mixins-shared.scss alert-variations-content
//    @include alert-variations(unquote(".alert"), unquote(""), $mdb-text-color-light);
//
//    &-info, &-danger, &-warning, &-success, &-rose {
//        color: $mdb-text-color-light;
//    }
//
//    &-default {
//        a, .alert-link {
//            color: $mdb-text-color-primary;
//        }
//    }
//
//    span{
//        display: block;
//        max-width: 89%;
//    }
//
//    &.alert-danger{
//        @include shadow-alert-color($brand-danger);
//        @include alert-icon-color($brand-danger);
//    }
//    &.alert-warning{
//        @include shadow-alert-color($brand-warning);
//        @include alert-icon-color($brand-warning);
//    }
//    &.alert-success{
//        @include shadow-alert-color($brand-success);
//        @include alert-icon-color($brand-success);
//    }
//    &.alert-info{
//        @include shadow-alert-color($brand-info);
//        @include alert-icon-color($brand-info);
//    }
//    &.alert-primary{
//        @include shadow-alert-color($brand-primary);
//        @include alert-icon-color($brand-primary);
//    }
//    &.alert-rose{
//        @include shadow-alert-color($brand-rose);
//        @include alert-icon-color($brand-rose);
//    }
//
//    &.alert-with-icon{
//      padding-left: 66px;
//
//      i[data-notify="icon"] {
//        font-size: 30px;
//        display: block;
//        left: 15px;
//        position: absolute;
//        top: 50%;
//        margin-top: -15px;
//        color: #fff;
//      }
//    }
//
//    .mat-button.close{
//      min-width: auto;
//      line-height: .5;
//        i{
//          color: $white-color;
//          font-size: 11px;
//        }
//    }
//
//    i[data-notify="icon"]{
//        display: none;
//    }
//
//    .alert-icon{
//        display: block;
//        float: left;
//        margin-right: $margin-base;
//
//        i{
//            margin-top: -7px;
//            top: 5px;
//            position: relative;
//        }
//    }
//
//    [data-notify="dismiss"]{
//        margin-right: 5px;
//    }
//}
//
//.places-buttons .btn {
//    margin-bottom: 30px;
//}
////
//// .alert {
////     border: 0;
////     border-radius: 3px;
////
////     padding: 20px 15px;
////     line-height: 20px;
////
////     //@include shadow-z-2();
////
////     b{
////         font-weight: $font-weight-bold;
////         text-transform: uppercase;
////         font-size: $font-size-small;
////     }
////     // SASS conversion note: please mirror any content change in _mixins-shared.scss alert-variations-content
////     @include alert-variations(unquote(".alert"), unquote(""), $mdb-text-color-light);
////
////     &-info, &-danger, &-warning, &-success {
////         color: $mdb-text-color-light;
////     }
////
////     &-default {
////         a, .alert-link {
////             color: $mdb-text-color-primary;
////         }
////     }
////
////     .alert-icon{
////         display: block;
////         float: left;
////         margin-right: $margin-base;
////
////         i{
////             margin-top: -7px;
////             top: 5px;
////             position: relative;
////         }
////     }
////     .mat-button.close,
////     .close{
////         min-width: auto;
////         color: $white-color;
////         text-shadow: none;
////         opacity: .9;
////
////         i{
////             font-size: 11px;
////         }
////
////         &:hover,
////         &:focus{
////             opacity: 1;
////         }
////     }
//// }
//// .alert .close {
////     line-height: .5;
//// }
