.card-plain {
    background: transparent;
    box-shadow: none;

    .card-header:not(.card-avatar) {
        margin-left: 0;
        margin-right: 0;
    }

    .card-body {
        padding-left: 5px;
        padding-right: 5px;
    }

    .card-header-image {
        margin: 0 !important;
        border-radius: $border-radius-large;

        img {
          border-radius: $border-radius-large;
        }
    }
    .card-footer {
        padding-left: 5px;
        padding-right: 5px;
        background-color: transparent;
    }
}

.analysis-select-card{
  position: relative;
  top:0;
  transition: all .2s;
  cursor: pointer;
  margin: auto;
  //transition: top .5s;
  min-height: 100px;
  &:hover{
    top: 4px;
    box-shadow: 0 1px 3px 1px rgba($brand-primary, 0.4);
  }
}
