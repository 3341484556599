@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  .my-custom-button {
    // Read the 500 hue from the primary color palette.
    color: mat.get-color-from-palette($primary-palette, 500);
    border: 2px solid #000;
  }
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);

  .my-custom {
    font-family: mat.font-family($typography-config);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}

